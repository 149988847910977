<template>
  <!--begin::Container-->
  <div class="wrapper">
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="390px"
       
        :src="coverUrl"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 65px;">
          <div class="md-layout justify-md-center ">
    
          </div>
        </div>
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;">
      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-lg-8">

                      <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="display-3  font-weight-bolder">
                {{ companyname }}
              </div>
              <div class="mt-5">
                <h4 class="">
                  {{ promo_text }}
                </h4>
              </div>
            </div>
              <div class="section section-text pb-0">


                <h1 class="header">
                  Welcome
                </h1>
                <p class="" v-html="teaserData"></p>

                <h4 class="header">
                  Our Story...
                </h4>
                <p v-html="descData"></p>
            
              </div>

              <div class="md-layout">
                <h3 class="display-4 pb-10">Featured Products</h3>

                <v-row no-gutters>
                  <v-col
                    v-for="(product, index) in products"
                    :key="index"
                    cols="12"
                    sm="4"
                  >
                    <blog-card
                      class="  h-100 ml-4"
                      card-plain
                      :shadow-normal="false"
                      :no-colored-shadow="false"
                      :card-image="
                        product.main_image != null
                          ? baseurl + product.main_image.url
                          : '/media/bike1.jpg'
                      "
                    >
                      <template slot="cardContent">
                        <h6 class="card-category text-info">
                          Featured Product
                        </h6>
                        <h4 class="card-title">
                          {{ product.name }}
                        </h4>
                        <p
                          class="card-description "
                          v-html="product.short_description"
                        >
                          {{ product.short_description }}
                        </p>
                        <h6 class="font-weight-bold my-2">
                          Case Pack - {{ product.case_pack }} units
                        </h6>
                      </template>
                    </blog-card>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="mt-10" style="position:relative;">
                <v-img
                  class="img-raised"
                  height="400"
                  :src="descImgUrl"
                ></v-img>
              </div>
              <div class="  follow ">
                <v-btn
                  tile
                  medium
                  color="secondary"
                  @click="contactDialog = true"
                >
                  <v-icon left>
                    mdi-pencil
                  </v-icon>
                  Contact Supplier
                </v-btn>

                <template
                  v-if="
                    currentUser.role != undefined &&
                      currentUser.role.type == 'seller'
                  "
                >
                  <v-btn
                    v-if="hasApplication == false"
                    tile
                    medium
                    color="primary"
                    @click="apply"
                  >
                    <v-icon left>
                      mdi-pencil
                    </v-icon>
                    Apply Now
                  </v-btn>

                  <v-btn v-else tile disabled medium> Already applied </v-btn>
                </template>
              </div>

              <div class="mt-4">
                <pricing-card style="background:#EEA47F;">
                  <template slot="cardLeft">
                    <h6 class="card-category text-white">
                      Supplier
                    </h6>
                    <h1 class="card-title text-white">
                      <small> Quick Details</small>
                    </h1>
                  </template>

                  <template slot="cardRight">
                    <h6 class="card-category text-white">
                      Contact: {{ email }}
                    </h6>

                    <div v-show="instagram != ''">
                      <a
                        :href="instagram"
                        class="text-light-50 ml-16 text-hover-primary font-weight-bold"
                      >
                        <i class="flaticon2-placeholder mr-2 font-size-xlg"></i
                        >Instagram
                      </a>
                    </div>
                    <div v-show="linkedin != ''">
                      <a
                        :href="linkedin"
                        class="text-white-50 ml-16 text-hover-primary font-weight-bold"
                      >
                        <i class="flaticon2-placeholder mr-2 font-size-xlg"></i
                        >Linkedin
                      </a>
                    </div>
                  </template>

                  <template slot="cardContent">
                    <ul>
                      <li><b>Industry:</b> {{ category }}</li>
                      <li><b>Average Margin</b> {{ averageMargin }}%</li>
                      <li>
                        <b>Sales Model: </b> <br />
                        {{ salesModel }}
                      </li>
                      <li><b>Marketing Rebate: </b> {{ mdf }}%</li>
                      <li>
                        <b-modal v-model="policyShow" hide-footer>
                          <template #modal-title>
                            Map Policy
                          </template>
                          <div class="">
                            <h3 v-if="mapPolicy != ''" v-html="mapPolicy"></h3>
                            <h3 v-else>CONTACT FOR MORE INFO</h3>
                          </div>
                        </b-modal>
                        <a
                          v-if="hasPolicy"
                          href="#"
                          class="text-white font-weight-bolder"
                          @click="policyShow = !policyShow"
                        >
                          Map Policy Info
                        </a>
                        <span v-else class="font-weight-bolder">
                          No
                        </span>
                      </li>
                    </ul>
                  </template>
                </pricing-card>
              </div>

              <md-card class="mt-5 mb-4">
                <md-card-content>
                  <h6 class="card-category">
                    <md-icon class="text-danger">
                      trending_up
                    </md-icon>
                    Dealer Requirements
                  </h6>
                  <div
                    class=" align-items-lg-center flex-column flex-lg-row p-3"
                    v-html="dealerData"
                  ></div>
                </md-card-content>
                <md-card-actions> </md-card-actions>
              </md-card>

              <full-bg-card card-image="/media/office2.jpg">
                <template slot="cardContent">
                  <h6 class="card-category text-white">
                    Find New Suppliers
                  </h6>
                  <a href="javascript:void(0)">
                    <h3 class="card-title">
                      The Goose is the best source of NEW PRODUCTS
                    </h3>
                  </a>
                  <p class="card-description text-white">
                    Don't be scared of trade shows or manufacturer reps. Source
                    direct, no middlemen...
                  </p>
                </template>
              </full-bg-card>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-7"></div>
            <div class="col-lg-5"></div>
          </div>

          <!--begin::Row-->
          <!--end::Row-->

          <!--end::Container-->
        </div>
      </div>
    </div>
    <v-dialog v-model="contactDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Contact Supplier</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="d-flex" cols="12" sm="12">
                <v-textarea
                  v-model="message"
                  outlined
                  label="message"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="contactDialog = false"
            >Close</v-btn
          >
          <v-btn color="blue darken-1" text @click="contactSupplier"
            >Send</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters, mapActions } from "vuex";

import gql from "graphql-tag";
import FullBgCard from "../../../components/base/cards/FullBgCard.vue";
import BlogCard from "../../../components/base/cards/BlogCard.vue";
import PricingCard from "../../../components/base/cards/PricingCard.vue";
export default {
  name: "Profile",
  components: {
    BlogCard,
    FullBgCard,
    PricingCard,
  },
  data() {
    return {
      message: "",
      contactDialog: false,
      baseurl: '',
      file: null,
      coverUrl: "",
      descImgUrl: "",
      companyname: "",
      city: "",
      state: "",
      email: "",
      phone: "",
      website: "",
      averageMargin: "",
      category: "",
      mdf: "",
      instagram: "",
      linkedin: "",
      promo_text: "",
      mapPolicy: "",
      hasPolicy: false,
      salesModel: "",
      teaserData: "",
      descData: "",
      dealerData: "",
      policyShow: false,
      hasQuestion: false,
      questionId: null,
      hasApplication: false,

    };
  },

  apollo: {
    products: {
      query: gql`
        query Products($supplier: ID!) {
          products: products(
            where: {
              supplier: { id: $supplier }
              featured: true
              status: "active"
            }
          ) {
            id
            name
            part_id
            case_pack
            short_description
            main_image {
              url
            }
          }
        }
      `,
      variables() {
        return {
          supplier: parseInt(this.$route.params.id),
        };
      },
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    setTimeout(() => {
      this.getCompanyInfo({
        id: this.$route.params.id,
      }).then((res) => {
        if (res.data.has_question != null) {
          this.hasQuestion = res.data.has_question;
        }
        if (res.data.question != null) {
          this.questionId = res.data.question.id;
        }
        var baseurl =
          '';
        if (
          res.data.profile.profile_cover != null &&
          res.data.profile.profile_cover != undefined
        ) {
          this.coverUrl = baseurl + res.data.profile.profile_cover.url;
        }
        if (
          res.data.profile.profile_desc_img != null &&
          res.data.profile.profile_desc_img != undefined
        ) {
          this.descImgUrl = baseurl + res.data.profile.profile_desc_img.url;
        }
        this.companyname = res.data.profile.profile_company_name;
        this.city = res.data.profile.profile_city;
        this.state = res.data.profile.profile_state;
        this.email = res.data.profile.profile_contact_email;
        this.phone = res.data.profile.profile_phone;
        this.instagram = res.data.profile.instagram;
        this.linkedin = res.data.profile.linkedin;
        this.promo_text = res.data.profile.promo_text;
        this.website = res.data.profile.profile_website;
        this.averageMargin = res.data.profile.profile_average_margin;
        this.mdf = res.data.profile.profile_mdf_percent;
        this.mapPolicy = res.data.profile.profile_map_text;
        this.hasPolicy = res.data.profile.profile_map_policy;
        this.salesModel = res.data.profile.profile_sales_model;
        this.category = res.data.profile.profile_category;
        if (
          res.data.profile.profile_teaser_description != null &&
          res.data.profile.profile_teaser_description != undefined
        ) {
          this.teaserData = res.data.profile.profile_teaser_description;
        }
        if (
          res.data.profile.profile_long_description != null &&
          res.data.profile.profile_long_description != undefined
        ) {
          this.descData = res.data.profile.profile_long_description;
        }
        if (
          res.data.profile.profile_dealer_requirements != null &&
          res.data.profile.profile_dealer_requirements != undefined
        ) {
          this.dealerData = res.data.profile.profile_dealer_requirements;
        }

        this.getApplicationInfo({
          supplier_company: this.$route.params.id,
          seller_company: this.$store.state.auth.user.company_relation,
        }).then((res) => {
          if (res.data.length == 0) {
            this.hasApplication = false;
          } else {
            this.hasApplication = true;
          }
        });
      });
    }, 1000);
  },
  methods: {
    ...mapActions([
      "getCompanyInfo",
      "getContactTotalCount",
      "submitContact",
      "getApplicationInfo",
      "submitApplication",
    ]),
    async contactSupplier() {
      const message = this.message;

      if (message != "") {
        let res = await this.getContactTotalCount({
          params:
            "supplier_company=" +
            parseInt(this.$route.params.id) +
            "&seller_company=" +
            parseInt(this.$store.state.auth.user.company_relation),
        });
        if (res.data == 0) {
          await this.submitContact({
            supplier_company: parseInt(this.$route.params.id),
            seller_company: parseInt(
              this.$store.state.auth.user.company_relation
            ),
            status: "public",
          });
        }

        let cres = await this.getCompanyInfo({
          id: parseInt(this.$store.state.auth.user.company_relation),
        });
        let emails = [];
        for (let i in cres.data.company_users) {
          if (cres.data.company_users[i].get_notice == true) {
            emails.push(cres.data.company_users[i].email);
          }
        }
        // let receiver_email = emails.join("-");

        // await this.$apollo.mutate({
        //   client: "chatClient",
        //   mutation: gql`
        //     mutation SendMessageMutation(
        //       $seller_id: Int!
        //       $supplier_id: Int!
        //       $message: String!
        //       $sender: String!
        //       $receiver_email: String!
        //     ) {
        //       sendMessage(
        //         seller_id: $seller_id
        //         supplier_id: $supplier_id
        //         message: $message
        //         is_seller: 1
        //         sender: $sender
        //         receiver_email: $receiver_email
        //       ) {
        //         id
        //         seller_id
        //         supplier_id
        //         message
        //         date
        //         is_seller
        //       }
        //     }
        //   `,
        //   variables: {
        //     seller_id: parseInt(this.$store.state.auth.user.company_relation),
        //     supplier_id: parseInt(this.$route.params.id),
        //     message,
        //     sender:
        //       this.$store.state.auth.user.FirstName +
        //       " " +
        //       this.$store.state.auth.user.LastName,
        //     receiver_email: receiver_email,
        //   },
        // });

        this.message = "";
        this.contactDialog = false;

        Swal.fire({
          title: "",
          text: "The message has been successfully sent!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
      }
    },
    apply() {
      if (this.hasApplication == false) {
        if (this.hasQuestion) {
          this.$router.push(
            "/seller/applicationanswer/" + this.$route.params.id
          );
        } else {
          this.submitApplication({
            supplier_company: this.$route.params.id,
            seller_company: this.$store.state.auth.user.company_relation,
            status: "pending",
          })
            .then(() => {
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              this.hasApplication = true;
            })
            .catch(() => {
              this.$bvToast.toast("An Error Occured!", {
                title: "Error",
                variant: "danger",
                toaster: "b-toaster-bottom-right",
                solid: true,
                appendToast: true,
              });
            });
        }
      }
    },
  },
};
</script>
